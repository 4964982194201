// extracted by mini-css-extract-plugin
export var azureBg_block = "styles-module--azureBg_block--lNSAu";
export var dashed = "styles-module--dashed--Nqk4w";
export var manCircle_block = "styles-module--manCircle_block--hVYdd";
export var manContainer = "styles-module--manContainer--M6U+S";
export var manCube_block = "styles-module--manCube_block--3Un0b";
export var manEar_block = "styles-module--manEar_block--FD1Ar";
export var manFace_block = "styles-module--manFace_block--5MlUl";
export var manFace_container = "styles-module--manFace_container--YPX5A";
export var manGlasses_block = "styles-module--manGlasses_block--tsB4L";
export var manHairBg_block = "styles-module--manHairBg_block--WZoXD";
export var manHair_block = "styles-module--manHair_block--2oWAc";
export var manHair_container = "styles-module--manHair_container--cAx12";
export var manTriangle_block = "styles-module--manTriangle_block--BXLrY";
export var polygon = "styles-module--polygon--P7Nmt";