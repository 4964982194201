// extracted by mini-css-extract-plugin
export var azureBird_block = "styles-module--azureBird_block--MQZ5Y";
export var azureBird_container = "styles-module--azureBird_container--lwzuK";
export var birds_container = "styles-module--birds_container--GTt7D";
export var dashed = "styles-module--dashed--90tNZ";
export var fonBg_bird_block = "styles-module--fonBg_bird_block--tKesO";
export var polygon = "styles-module--polygon--TCfwD";
export var whiteBird_block = "styles-module--whiteBird_block--3StUG";
export var whiteBird_container = "styles-module--whiteBird_container--QuUuF";
export var whiteCircle_bird_block = "styles-module--whiteCircle_bird_block--iwHCw";
export var whiteCircle_container = "styles-module--whiteCircle_container--VFhLv";