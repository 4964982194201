// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--9u+mS";
export var bigContainer = "styles-module--bigContainer--ydRf+";
export var container = "styles-module--container--s3Y9b";
export var headerBg_about = "styles-module--headerBg_about--L0y1f";
export var headerBg_time = "styles-module--headerBg_time--xotUu";
export var preview = "styles-module--preview--xG4MR";
export var preview__animations = "styles-module--preview__animations--cO6fR";
export var preview__informations = "styles-module--preview__informations--i-yAz";
export var preview__row = "styles-module--preview__row--DXhyW";
export var preview__scroll = "styles-module--preview__scroll--ocDEi";
export var preview__subtitle = "styles-module--preview__subtitle--oLswr";
export var preview__title = "styles-module--preview__title--YMK3H";