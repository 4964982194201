import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, FreeMode} from 'swiper';
import "./styles.scss";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/free-mode";

const AboutTicker = () => {
    const [tickers, setTickers] = useState([{id:1, direction: "left"}])

    const mobileTickersArr = [{id:1, 'direction': "left"}, {id:2, direction: "right"}, {id:3, direction: "left"}]

    useEffect(()=>{
        if(window.innerWidth <= 767){
           setTickers( () => mobileTickersArr)
        }
    }, [])

    const tickerItems = [
        {id:1, text:"◌ Freedom"},
        {id:2, text:"◌ Independence"},
        {id:3, text:"◌ Intelligence"},
        {id:4, text:"◌ Sociability"},
        {id:5, text:"◌ Digitality"},
        {id:6, text:"◌ Globality"},
    ];

    const handleDirection = (ticker) =>{
        if(ticker.direction === "right"){
            return {reverseDirection: true, delay: 0, disableOnInteraction: false}
        }else{
            return {delay: 0, disableOnInteraction: false}
        }
    }
    return (
        <>
            {tickers.map(ticker =>
                <Swiper
                    key={ticker.id}
                    className="ticker_swiper"
                    modules={[FreeMode, Autoplay]}
                    loop
                    grabCursor
                    freeMode
                    speed={5000}
                    slidesPerView="auto"
                    autoplay = {handleDirection(ticker)}
                >
                    <div className="ticker">
                        {tickerItems.map(item =>
                            <div key={item.id} className="ticker_container">
                                <SwiperSlide id={item.id}><p className="ticker__element">{item.text}</p></SwiperSlide>
                                <div className="ticker-modal__opacity"></div>
                            </div>
                        )}
                    </div>
                </Swiper>
            )}
        </>
    );
};

export default AboutTicker;