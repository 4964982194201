// extracted by mini-css-extract-plugin
export var column_reverse = "styles-module--column_reverse--iuLUg";
export var ourInformation = "styles-module--ourInformation--sm16H";
export var ourInformation_block = "styles-module--ourInformation_block--z9dHM";
export var ourInformation_img = "styles-module--ourInformation_img--RImIQ";
export var ourInformation_item = "styles-module--ourInformation_item--q+7Rw";
export var ourInformation_lineBg = "styles-module--ourInformation_lineBg--7x5xn";
export var ourInformation_metahumans = "styles-module--ourInformation_metahumans--bKsPv";
export var ourInformation_metahumans__animation = "styles-module--ourInformation_metahumans__animation--PikgX";
export var ourInformation_mission = "styles-module--ourInformation_mission--lIc+A";
export var ourInformation_mission__animation = "styles-module--ourInformation_mission__animation--PHSHQ";
export var ourInformation_philosophy = "styles-module--ourInformation_philosophy--8hZqa";
export var ourInformation_philosophy__animation = "styles-module--ourInformation_philosophy__animation--PzBpZ";
export var ourInformation_row = "styles-module--ourInformation_row--4OMGJ";
export var ourInformation_subtitle = "styles-module--ourInformation_subtitle--x46Ge";
export var ourInformation_text = "styles-module--ourInformation_text--uYkjr";
export var ourInformation_title = "styles-module--ourInformation_title--D-ubk";
export var ourValues = "styles-module--ourValues--mU59S";
export var ourValues_block = "styles-module--ourValues_block--vb26i";
export var ourValues_column = "styles-module--ourValues_column--gCZfO";
export var ourValues_column__text = "styles-module--ourValues_column__text--lBqLu";
export var ourValues_grid__earth = "styles-module--ourValues_grid__earth--2NVHN";
export var ourValues_grid__head = "styles-module--ourValues_grid__head--BCbKs";
export var ourValues_grid__health = "styles-module--ourValues_grid__health--KQNvX";
export var ourValues_grid__humanity = "styles-module--ourValues_grid__humanity--Wsvzn";
export var ourValues_grid__lamp = "styles-module--ourValues_grid__lamp--ERHFf";
export var ourValues_grid__move = "styles-module--ourValues_grid__move--U3vvb";
export var ourValues_grid__security = "styles-module--ourValues_grid__security--aJpqn";
export var ourValues_grid__sociable = "styles-module--ourValues_grid__sociable--SkXpg";
export var ourValues_rhombus = "styles-module--ourValues_rhombus--mTIdE";
export var ourValues_row = "styles-module--ourValues_row--cRceq";
export var ourValues_title = "styles-module--ourValues_title--RXPSR";
export var ourValues_triangle = "styles-module--ourValues_triangle--YTV+j";
export var personality = "styles-module--personality--mlhFc";
export var personality_moreText = "styles-module--personality_moreText--g8I77";
export var personality_title = "styles-module--personality_title--3eLEm";