// extracted by mini-css-extract-plugin
export var curvedLine_container = "styles-module--curvedLine_container--2ZYV5";
export var darkStar_container = "styles-module--darkStar_container--Gqfdf";
export var dashed = "styles-module--dashed--2bmMI";
export var missionStar_block = "styles-module--missionStar_block--6J7hC";
export var ourCircle_container = "styles-module--ourCircle_container--YxR7w";
export var ourMission_circle_block = "styles-module--ourMission_circle_block--VLdhL";
export var ourMission_container = "styles-module--ourMission_container--BUJIf";
export var ourMission_curvedLine_block = "styles-module--ourMission_curvedLine_block--KJ5ZK";
export var ourMission_darkStar_block = "styles-module--ourMission_darkStar_block--mbo+g";
export var ourMission_puzzleFigure_block = "styles-module--ourMission_puzzleFigure_block--7nM0g";
export var ourMission_star_block = "styles-module--ourMission_star_block--szx+A";
export var polygon = "styles-module--polygon--2355-";
export var rightStoreStar_container = "styles-module--rightStoreStar_container--gzlPC";