// extracted by mini-css-extract-plugin
export var aboutHeader_container = "styles-module--aboutHeader_container--jtjSo";
export var aboutLogo_circle = "styles-module--aboutLogo_circle--EbIUb";
export var aboutLogo_container = "styles-module--aboutLogo_container--75Fai";
export var aboutLogo_line = "styles-module--aboutLogo_line--nFHlz";
export var azureCircle_block = "styles-module--azureCircle_block--Sh91x";
export var azureCircle_container = "styles-module--azureCircle_container--kYsa7";
export var darkCircle_block = "styles-module--darkCircle_block--JYxzm";
export var dashed = "styles-module--dashed--x10qh";
export var logo_block = "styles-module--logo_block--ol419";
export var polygon = "styles-module--polygon--e8ioq";